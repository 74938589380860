/* eslint-disable react/prop-types */
import React from "react";
import { projectFirestore } from "../../config/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router";

//FIXME make cols wider on small screens. might as well use whole width.

export default function EventsList({ events }) {
  const { user, authIsReady } = useAuthContext;
  const navigate = useNavigate();

  if (events.length === 0) {
    return <div className="error">No events found...</div>;
  }
  return (
    <div className="container mt-3">
      <div className="">
        {events.map((event) => {
          const start = new Date(event.startDate).setHours(0, 0, 0, 0);
          const today = new Date().setHours(0, 0, 0, 0);
          const end = new Date(event.endDate).setHours(0, 0, 0, 0);

          const diffTime = Math.ceil(start - today);
          const diffTimeEnd = Math.ceil(end - today);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          const diffDaysEnd = Math.ceil(diffTimeEnd / (1000 * 60 * 60 * 24));

          let venue;
          if (event.venueName && event.eventTown) {
            venue = event.venueName + ", " + event.eventTown;
          }
          return (
            <div onClick={() => navigate(`/events/${event.id}`)} key={event.id} className="card text-bg-light events-list mb-3">
              <div className="row m-0">
                <div className="col col-lg-8 p-0">
                  <div className="card-header fw-bold">{event.eventName.toUpperCase()}</div>
                  <div className="card-body">
                    <i className="bi bi-geo-alt me-1"></i>
                    {venue || event.eventLocation} <br /> <i className="bi bi-calendar me-1"></i>
                    {event.startDate.split("-").reverse().join("/")} to {event.endDate.split("-").reverse().join("/")}
                  </div>{" "}
                </div>
                <div className="row m-0 d-lg-none p-0 ">
                  {/* mobile view */}
                  {/* if event is not started */}
                  <div className="col d-flex p-0">
                    {diffDays > 0 && (
                      <div className="d-flex border border-success text-bg-success flex-fill">
                        <div className="align-self-center text-center flex-fill">
                          <h1 className="m-0 ">{diffDays}</h1>
                          <span>days to go</span>
                        </div>
                      </div>
                    )}
                    {/* started not finished */}
                    {diffDays <= 0 && diffDaysEnd >= 0 && (
                      <div className="p-0 d-flex border border-warning text-bg-warning flex-fill">
                        <div className="align-self-center text-center flex-fill">
                          <h4 className="m-0 ">
                            In
                            <br />
                            progress
                          </h4>
                        </div>
                      </div>
                    )}
                    {/* finished */}
                    {diffDaysEnd < 0 && (
                      <div className="p-0 d-flex border border-danger text-bg-danger flex-fill">
                        <div className="align-self-center text-center flex-fill">
                          <h4 className="m-0 ">Finished</h4>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="align-self-center text-center flex-fill">
                      <h3 className="m-0">{event.players?.going?.length > 0 ? event.players?.going?.length : 0}</h3>
                      <span>players confirmed</span>
                      <p className="m-0">
                        <small>{event.players?.interested?.length > 0 ? event.players?.interested?.length : 0} interested</small>
                      </p>
                    </div>
                  </div>
                </div>
                {/* desktop view */}
                {/* if event is not started */}
                {diffDays > 0 && (
                  <div className="d-none d-lg-flex col-2 p-0 border border-success text-bg-success">
                    <div className="align-self-center text-center flex-fill">
                      <h1 className="m-0 ">{diffDays}</h1>
                      <span>days to go</span>
                    </div>
                  </div>
                )}
                {/* started not finished */}
                {diffDays <= 0 && diffDaysEnd >= 0 && (
                  <div className="d-none d-lg-flex col-2 p-0 border border-warning text-bg-warning">
                    <div className="align-self-center text-center flex-fill">
                      <h4 className="m-0 ">
                        In
                        <br />
                        progress
                      </h4>
                    </div>
                  </div>
                )}
                {/* finished */}
                {diffDaysEnd < 0 && (
                  <div className="d-none d-lg-flex col-2 p-0 border border-danger text-bg-danger">
                    <div className="align-self-center text-center flex-fill">
                      <h4 className="m-0 ">Finished</h4>
                    </div>
                  </div>
                )}
                <div className="d-none d-lg-flex col-lg-2 p-0 d-flex border border-dark text-center text-bg-light">
                  <div className="align-self-center text-center flex-fill">
                    <h1 className="m-0">{event.players?.going?.length > 0 ? event.players?.going?.length : 0}</h1>
                    <span>players confirmed</span>
                    <p className="m-0">
                      <small>{event.players?.interested?.length > 0 ? event.players?.interested?.length : 0} interested</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
