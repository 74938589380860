import { React, useState } from "react";
import FileResizer from "react-image-file-resizer";
import getCroppedImg from "../utils/getCroppedImage";
import { projectFirestore, projectStorage, timestamp } from "../config/config";
import firebase from "firebase/app"; // Important for Firebase 8.x

//team bio should be made of name, bio text, established date, location

export const useUpdateTeam = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  // Crop state management
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async (imageFile) => {
    try {
      const croppedImage = await getCroppedImg(imageFile, croppedAreaPixels);
      return croppedImage;
    } catch (error) {
      setError("Error cropping image");
      console.error(error);
    }
  };

  // Resize Image
  const resizeImage = (imageFile, w, h, callback) => {
    FileResizer.imageFileResizer(
      imageFile,
      w, // width
      h, // height
      "WEBP",
      90, // quality
      0, // rotation
      (uri) => {
        callback(uri);
      },
      "blob", // Output format: blob
    );
  };

  const updateTeamProfile = async (update, id) => {
    setError(null);
    setIsPending(true);
    try {
      const teamRef = projectFirestore.collection("teams").doc(id);
      await teamRef.update(update);
      setIsPending(false);
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  const updateAvatar = async (file, id) => {
    setError(null);
    setIsPending(true);
    try {
      const croppedImage = await cropImage(file);
      resizeImage(croppedImage, 300, 300, async (resizedImage) => {
        const storageRef = projectStorage.ref(`teams/${id}/avatar`);
        const teamRef = projectFirestore.collection("teams").doc(id);

        const uploadTask = storageRef.put(resizedImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },

          (error) => {
            console.log(error);
            setError("Failed to upload the image");
          },

          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

            await teamRef.update({
              avatar: downloadURL,
            });

            setIsPending(false);
          },
        );
      });
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };
  const updateBanner = async (file, id) => {
    setError(null);
    setIsPending(true);
    try {
      const croppedImage = await cropImage(file);
      resizeImage(croppedImage, 900, 360, async (resizedImage) => {
        const storageRef = projectStorage.ref(`teams/${id}/banner`);
        const teamRef = projectFirestore.collection("teams").doc(id);

        const uploadTask = storageRef.put(resizedImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },

          (error) => {
            console.log(error);
            setError("Failed to upload the image");
          },

          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

            await teamRef.update({
              banner: downloadURL,
            });

            setIsPending(false);
          },
        );
      });
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  return {
    error,
    isPending,
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    updateAvatar,
    updateBanner,
    updateTeamProfile,
  };
};
