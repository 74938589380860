/* eslint-disable react/prop-types */
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "../avatar/Avatar";
import { Modal } from "react-bootstrap";
import { useDocument } from "../../hooks/useDocument";
import { useFirestore } from "../../hooks/useFirestore";
import PlayerInfo from "../infoCard/InfoCard";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router";

export default function Members({ team }) {
  const { user, authIsReady } = useAuthContext();
  const userData = useDocument("aggregatedData", "users").document;
  const { updateDocument } = useFirestore("teams");
  const [pendingMembersModal, setPendingMembersModal] = useState(false);
  const [confirmedMembers, setConfirmedMembers] = useState([]);
  const [rejectedMembers, setRejectedMembers] = useState([]);
  const [showManageMemberModal, setShowManageMemberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const navigate = useNavigate();

  const handlePendingMemberSelection = (e) => {
    const [type, id] = e.target.id.split("-");
    if (type === "approve" && !confirmedMembers.includes(id)) {
      setConfirmedMembers([...confirmedMembers, id]);
      setRejectedMembers(rejectedMembers.filter((memberId) => memberId !== id));
    } else if (type === "reject" && !rejectedMembers.includes(id)) {
      setRejectedMembers([...rejectedMembers, id]);
      setConfirmedMembers(confirmedMembers.filter((memberId) => memberId !== id));
    }
  };
  const handleConfirmMemberChanges = () => {
    const newPendingMembers = team?.pendingMembers?.filter(
      (memberId) => !confirmedMembers.includes(memberId) && !rejectedMembers.includes(memberId),
    );
    const currentMembers = team?.members || [];
    const updatedMembers = [...currentMembers, ...confirmedMembers];
    updateDocument(team.id, {
      ...team,
      members: updatedMembers,
      pendingMembers: newPendingMembers,
      memberChanges: [...confirmedMembers, ...rejectedMembers],
    });
    setPendingMembersModal(false);
  };

  const handleManageMember = (memberId) => {
    setSelectedMember(userData?.users.find((user) => user.id === memberId));
    setShowManageMemberModal(true);
  };
  const handleRemoveMember = () => {
    if (team?.admins?.includes(selectedMember.id)) {
      alert(
        `Please remove ${selectedMember?.profileData.firstName} ${selectedMember?.profileData.surName} as an admin first before removing them from the team.`,
      );
      return;
    }
    const confirmRemoval = window.confirm(
      `Are you sure you would like to remove ${selectedMember?.profileData.firstName} ${selectedMember?.profileData.surName} from the team? This cannot be undone.`,
    );
    if (!confirmRemoval) return;
    console.log(team);
    let update = { ...team };
    if (team?.captains?.includes(selectedMember.id)) {
      const currentCaptains = team?.captains || [];
      const newCaptains = currentCaptains.filter((captain) => captain !== selectedMember.id);
      update = { ...update, captains: newCaptains };
    }
    if (team?.coCaptains?.includes(selectedMember.id)) {
      const currentCoCaptains = team?.coCaptains || [];
      const newCoCaptains = currentCoCaptains.filter((coCaptain) => coCaptain !== selectedMember.id);

      update = { ...update, coCaptains: newCoCaptains };
    }
    const currentMembers = team?.members || [];
    const updatedMembers = currentMembers.filter((memberId) => memberId !== selectedMember?.id);
    update = {
      ...update,
      members: updatedMembers,
      memberChanges: [selectedMember.id],
    };
    console.log(update);
    updateDocument(team.id, update);
    setShowManageMemberModal(false);
  };
  const handleUpdateCaptains = (opt) => {
    console.log("update captains", opt, selectedMember.id);
    if (opt === "add") {
      const currentCaptains = team?.captains || [];
      const newCaptains = [...currentCaptains, selectedMember.id];
      updateDocument(team.id, { ...team, captains: newCaptains });
    } else if (opt === "remove") {
      const currentCaptains = team?.captains || [];
      const newCaptains = currentCaptains.filter((captain) => captain !== selectedMember.id);
      updateDocument(team.id, { ...team, captains: newCaptains });
    }
  };
  const handleUpdateCoCaptains = (opt) => {
    console.log("update cocaptains", opt, selectedMember.id);
    if (opt === "add") {
      const currentCoCaptains = team?.coCaptains || [];
      const newCoCaptains = [...currentCoCaptains, selectedMember.id];

      updateDocument(team.id, { ...team, coCaptains: newCoCaptains });
    } else if (opt === "remove") {
      const currentCoCaptains = team?.coCaptains || [];
      const newCoCaptains = currentCoCaptains.filter((coCaptain) => coCaptain !== selectedMember.id);

      updateDocument(team.id, { ...team, coCaptains: newCoCaptains });
    }
  };
  const handleUpdateAdmins = (opt) => {
    if (opt === "add") {
      const confirmRemoval = window.confirm(
        `Warning: adding an admin will allow full access to team membership and profile settings, including adding and removing admins? Are you sure you want to continue?`,
      );
      if (!confirmRemoval) return;
      const currentAdmins = team?.admins || [];
      const newAdmins = [...currentAdmins, selectedMember.id];

      updateDocument(team.id, { ...team, admins: newAdmins });
    } else if (opt === "remove") {
      const currentAdmins = team?.admins || [];
      const newAdmins = currentAdmins.filter((admin) => admin !== selectedMember.id);

      updateDocument(team.id, { ...team, admins: newAdmins });
    }
  };
  return (
    <div className="card p-3">
      <div className="d-flex justify-content-end">
        {team?.pendingMembers?.length > 0 && team?.admins?.includes(user?.uid) && (
          <button className="btn btn-primary align-self-start" onClick={() => setPendingMembersModal(!pendingMembersModal)}>
            Pending Members
          </button>
        )}
      </div>

      <h2>Roster</h2>

      <div>
        <h3>Captains</h3>
        <div className="d-flex mb-2">
          {team &&
            userData &&
            team?.captains?.map((thisMember) => {
              const player = userData?.users.find((user) => user.id === thisMember);
              return (
                <div
                  onClick={() => navigate(`/profile/${player?.id}`)}
                  key={player?.id}
                  className="player-attendance-card p-2 m-1 bg-light"
                  role="button"
                >
                  <div className="d-flex position-relative m-1">
                    <Avatar src={player?.profileData?.photoURL} className="align-self-center" />
                    <span className="align-self-center ms-3">
                      <div>
                        {player?.profileData.firstName} {player?.profileData.alias ? `'${player?.profileData.alias}'` : ""}{" "}
                        {player?.profileData.surName}{" "}
                        {team?.admins?.includes(player?.id) && <i className="bi bi-wrench text-info"></i>}
                        {team?.admins?.includes(user?.uid) && (
                          <>
                            <i
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering the parent onClick
                                handleManageMember(player?.id);
                              }}
                              className="bi bi-pencil-square text-primary me-1 d-inline pe-auto"
                              role="button"
                            ></i>
                          </>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              );
            })}
          {(!team?.captains || team?.captains?.length === 0) && <p className="text-muted">No Captains</p>}
        </div>
      </div>
      <div>
        <h3>Co-Captains</h3>
        <div className="d-flex mb-2">
          {team &&
            userData &&
            team?.coCaptains?.map((thisMember) => {
              const player = userData?.users.find((user) => user.id === thisMember);
              return (
                <div
                  onClick={() => navigate(`/profile/${player?.id}`)}
                  key={player?.id}
                  className="player-attendance-card p-2 m-1 bg-light"
                  role="button"
                >
                  <div className="d-flex position-relative m-1">
                    <Avatar src={player?.profileData?.photoURL} className="align-self-center" />
                    <span className="align-self-center ms-3">
                      <div>
                        {player?.profileData.firstName} {player?.profileData.alias ? `'${player?.profileData.alias}'` : ""}{" "}
                        {player?.profileData.surName}{" "}
                        {team?.admins?.includes(player?.id) && <i className="bi bi-wrench text-info"></i>}
                        {team?.admins?.includes(user?.uid) && (
                          <>
                            {" "}
                            <i
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering the parent onClick
                                handleManageMember(player?.id);
                              }}
                              className="bi bi-pencil-square text-primary me-1 d-inline pe-auto"
                              role="button"
                            ></i>
                          </>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              );
            })}
          {(!team?.coCaptains || team?.coCaptains?.length === 0) && <p className="text-muted">No Co-Captains</p>}
        </div>
      </div>
      <div>
        <h3>Members</h3>
        <div className="d-flex mb-2">
          {team?.members &&
            userData &&
            team?.members
              .filter((memberId) => !team?.captains?.includes(memberId) && !team?.coCaptains?.includes(memberId))
              .map((thisMember) => {
                const player = userData?.users.find((user) => user.id === thisMember);
                return (
                  <div
                    onClick={() => navigate(`/profile/${player?.id}`)}
                    key={player?.id}
                    className="player-attendance-card p-2 m-1 bg-light"
                    role="button"
                  >
                    <div className="d-flex position-relative m-1">
                      <Avatar src={player?.profileData?.photoURL} className="align-self-center" />
                      <span className="align-self-center ms-3">
                        <div>
                          {player?.profileData.firstName} {player?.profileData.alias ? `'${player?.profileData.alias}'` : ""}{" "}
                          {player?.profileData.surName}{" "}
                          {team?.admins?.includes(player?.id) && <i className="bi bi-wrench text-info"></i>}
                          {team?.admins?.includes(user?.uid) && (
                            <>
                              {" "}
                              <i
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents triggering the parent onClick
                                  handleManageMember(player?.id);
                                }}
                                className="bi bi-pencil-square text-primary me-1 d-inline pe-auto"
                                role="button"
                              ></i>
                            </>
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                );
              })}
          {(!team?.members || team?.members?.length === 0) && <p className="text-muted">No Members</p>}
        </div>
      </div>

      {/* Pending Members */}
      <Modal
        show={pendingMembersModal}
        id="pendingMembersModal"
        title="Pending Members"
        onHide={() => setPendingMembersModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pending Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userData &&
            team?.pendingMembers?.map((member) => {
              const user = userData?.users?.find((user) => user.id === member);

              return (
                <div key={user.id} className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center">
                    <Avatar src={user?.profileData.photoURL} />
                    <p className="ms-3">
                      {user?.profileData?.firstName} {user?.profileData?.alias ? `'${user?.profileData?.alias}'` : ""}{" "}
                      {user?.profileData?.surName}
                    </p>
                  </div>
                  <div className="d-flex">
                    <div className="btn-group" data-bs-toggle="buttons">
                      <input
                        className="btn-check"
                        type="radio"
                        name={`pendingMemberSelection-${user?.id}`}
                        id={`approve-${user?.id}`}
                        autoComplete="off"
                        onClick={(e) => handlePendingMemberSelection(e)}
                      />
                      <label className="btn btn-outline-success" htmlFor={`approve-${user?.id}`}>
                        Approve
                      </label>

                      <input
                        className="btn-check"
                        type="radio"
                        name={`pendingMemberSelection-${user?.id}`}
                        id={`reject-${user?.id}`}
                        autoComplete="off"
                        onClick={(e) => handlePendingMemberSelection(e)}
                      />
                      <label className="btn btn-outline-danger" htmlFor={`reject-${user?.id}`}>
                        Reject
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          {team?.pendingMembers?.length > 0 && (
            <>
              <hr />
              <button
                type="button"
                className="btn btn-primary w-100 mt-3"
                onClick={() => {
                  handleConfirmMemberChanges();
                }}
              >
                Confirm All Pending Members
              </button>
            </>
          )}
          {team?.pendingMembers?.length === 0 && <p>No pending members</p>}
        </Modal.Body>
      </Modal>

      {/* Manage Members Modal */}
      <Modal show={showManageMemberModal} onHide={() => setShowManageMemberModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Manage {selectedMember?.profileData.firstName}{" "}
            {selectedMember?.profileData?.alias ? `'${selectedMember?.profileData.alias}'` : ""}{" "}
            {selectedMember?.profileData.surName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-2">
            {!team?.coCaptains?.includes(selectedMember?.id) && (
              <>
                {team?.captains?.includes(selectedMember?.id) ? (
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    onClick={() => {
                      handleUpdateCaptains("remove");
                    }}
                  >
                    Remove Captain
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-gold w-100"
                    onClick={() => {
                      handleUpdateCaptains("add");
                    }}
                  >
                    <i className="bi bi-star-fill"></i> Make Captain
                  </button>
                )}
              </>
            )}
            {!team?.captains?.includes(selectedMember?.id) && (
              <>
                {team?.coCaptains?.includes(selectedMember?.id) ? (
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    onClick={() => {
                      handleUpdateCoCaptains("remove");
                    }}
                  >
                    Remove Co-Captain
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-silver w-100"
                    onClick={() => {
                      handleUpdateCoCaptains("add");
                    }}
                  >
                    <i className="bi bi-star"></i> Make Co-Captain
                  </button>
                )}
              </>
            )}

            {team?.admins?.includes(selectedMember?.id) ? (
              <button
                type="button"
                className="btn btn-danger w-100"
                onClick={() => {
                  handleUpdateAdmins("remove");
                }}
              >
                Remove Admin
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-info w-100"
                onClick={() => {
                  handleUpdateAdmins("add");
                }}
              >
                <i className="bi bi-wrench"></i> Make Admin
              </button>
            )}

            <button
              type="button"
              className="btn btn-danger w-100"
              onClick={() => {
                handleRemoveMember();
              }}
            >
              <i className="bi bi-x-lg"></i> Remove From Team
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
