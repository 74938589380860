import { useParams, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, React, useRef } from "react";
import { projectFirestore } from "../../config/config";
import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import Avatar from "../../components/avatar/Avatar";
import { Dropdown, DropdownButton, Button, Modal, Form, InputGroup, ListGroup } from "react-bootstrap";
import { useNewsFeedPost } from "../../hooks/useNewsFeedPost";
import { useDocument } from "../../hooks/useDocument";
import { PlacesAutocomplete } from "../../components/autocomplete/PlacesAutoComplete";
import { Conversation } from "../../components/conversation/Conversation";
import InfoCard from "../../components/infoCard/InfoCard";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import defaultProfile from "../../assets/defaultProfile.webp";
import TextDivider from "../../components/textDivider/TextDivider";
import LogisticsOverview from "../../components/logisticsOverview/LogisticsOverview";
import Spacer from "../../components/spacer/spacer";

//TODO make conversations work
//TODO finish logistics overview
//FIXME buttons are uneven on mobile
//TODO add private team events?
//TODO split this into seperate components
//TODO make only primary teams show on teams list

export default function EventDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, authIsReady } = useAuthContext();
  const { softDeleteDocument, updateDocument, response } = useFirestore("events");
  const { document: users } = useDocument("aggregatedData", "users");
  // FIXME remove deleted users from aggregatedData
  const { document: teamData } = useDocument("aggregatedData", "teams");
  const { newsCreate } = useNewsFeedPost();

  const [event, setEvent] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const [eventName, setEventName] = useState("");
  const [venueName, setVenueName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventTown, setEventTown] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventWebsite, setEventWebsite] = useState("");
  const [ticketSite, setTicketSite] = useState("");
  const [newSubEvents, setNewSubEvents] = useState("");
  const [subEvents, setSubEvents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState("");
  const [notes, setNotes] = useState("");
  const subEventInput = useRef(null);
  const teamInput = useRef(null);
  const [comment, setComment] = useState("");
  const [arrival, setArrival] = useState(null);
  const [sleeping, setSleeping] = useState(null);
  const [transport, setTransport] = useState(null);
  const [showLogisticsModal, setShowLogisticsModal] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [paint, setPaint] = useState("");
  const [arrivalSpecific, setArrivalSpecific] = useState(null);
  const [showLogisticsOverviewModal, setShowLogisticsOverviewModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [extras, setExtras] = useState([]);
  const [attendingTeams, setAttendingTeams] = useState([]);
  const [showAssignTeamsModal, setShowAssignTeamsModal] = useState(false);
  const [teamAssignments, setTeamAssignments] = useState({});
  const [teamFilter, setTeamFilter] = useState(null);
  const [teamFilterLogi, setTeamFilterLogi] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamPlayersGoing, setTeamPlayersGoing] = useState([]);
  const [teamPlayersInterested, setTeamPlayersInterested] = useState([]);
  const [teamPlayersLogi, setTeamPlayersLogi] = useState([]);
  const [teamPlayersGoingLogi, setTeamPlayersGoingLogi] = useState([]);
  const [teamPlayersInterestedLogi, setTeamPlayersInterestedLogi] = useState([]);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [unaffiliatedPlayers, setUnaffiliatedPlayers] = useState([]);
  const [primaryTeam, setPrimaryTeam] = useState("");

  document.title = `OtterBox - ${event?.eventName}`;

  const handleAddTeam = (e) => {
    e.preventDefault();
    const teamName = newTeam.trim();

    // Check if the team name is not empty and doesn't already exist in teams
    if (teamName && !teams.some((team) => team.name === teamName)) {
      const newTeam = {
        name: teamName, // Use teamName as the team name
        registeredTeams: [], // Initialize with an empty players array
      };

      // Add the new team object to the list
      setTeams((prevTeams) => [...prevTeams, newTeam]);

      // Reset the input and focus
      setNewTeam(""); // Clear the input field
      teamInput.current.focus(); // Set focus back to the input
    }
  };

  const handleToggleEventModal = () => {
    setShowEditEventModal(!showEditEventModal);
    handleEdit();
  };

  useEffect(() => {
    if (teamFilterLogi && users) {
      const teamPlayers = users?.users?.filter((user) => user?.profileData?.teams?.includes(teamFilterLogi.value));
      let teamPlayersGoing = teamPlayers?.filter((player) =>
        event?.players?.going?.map((player) => player.id).includes(player.id),
      );
      let teamPlayersInterested = teamPlayers?.filter((player) => event?.players?.interested?.includes(player.id));
      setTeamPlayersLogi(teamPlayers);
      setTeamPlayersGoingLogi(teamPlayersGoing);
      setTeamPlayersInterestedLogi(teamPlayersInterested);
    }
  }, [event, teamFilterLogi, users]);

  const handleToggleTeamModal = (team) => {
    let teamPlayers = [];
    if (team === "anon") {
      setSelectedTeam("anon");
      teamPlayers = users?.users?.filter((user) => !user.profileData.teams || user.profileData.teams.length === 0);
    } else {
      setSelectedTeam(team);
      teamPlayers = users?.users?.filter((user) => user.profileData.teams?.includes(team));
    }
    let teamPlayersGoing = teamPlayers.filter((player) => event.players.going.map((player) => player.id).includes(player.id));
    let teamPlayersInterested = teamPlayers.filter((player) => event.players.interested.includes(player.id));
    setTeamPlayersGoing(teamPlayersGoing);
    setTeamPlayersInterested(teamPlayersInterested);
    setShowTeamModal(!showTeamModal);
  };

  const handleRemoveTeam = (e) => {
    const str = e.target.innerHTML.trim(); // Get the team name from the element and trim any extra spaces

    // Find the index of the team object where the name matches the string
    const index = teams.findIndex((team) => team.name === str);

    if (index > -1) {
      // Create a new array excluding the team with the matched name
      const updatedTeams = [...teams];
      updatedTeams.splice(index, 1); // Remove the team at the found index

      // Update the state with the new array
      setTeams(updatedTeams);
    }
  };

  const handleAddSubEvent = (e) => {
    e.preventDefault();
    const eventName = newSubEvents.trim();

    // Check if the event name is not empty and doesn't already exist in subEvents
    if (eventName && !subEvents.some((subEvent) => subEvent.name === eventName)) {
      const newSubEvent = {
        name: eventName, // Use eventName as the sub-event name
        players: [], // Initialize with an empty players array
      };

      // Add the new sub-event object to the list
      setSubEvents((prevSubEvents) => [...prevSubEvents, newSubEvent]);
    }

    // Reset the input and focus
    setNewSubEvents(""); // Clear the input field
    subEventInput.current.focus(); // Set focus back to the input
  };

  const getDayOptions = () => {
    const startDateObj = new Date(event?.startDate);
    const endDateObj = new Date(event?.endDate);
    const timeDifference = endDateObj.getTime() - startDateObj.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const days = [];
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    for (let i = 0; i <= daysDifference; i++) {
      const date = new Date(startDateObj.getTime() + i * 1000 * 60 * 60 * 24);
      days.push({ value: dayNames[date.getDay()], label: dayNames[date.getDay()] });
    }
    return days;
  };

  const getTeamsOptions = () => {
    const teamsOptions = event?.teams?.map((team) => ({ value: team.name, label: team.name }));
    return teamsOptions;
  };

  const handleSubmitAttendanceData = async (e) => {
    e.preventDefault();
    setShowLogisticsModal(false);

    // Create the attendance data object
    const attendanceData = {
      arrival,
      arrivalSpecific: arrivalSpecific || null,
      sleeping,
      transport,
      paint: paint || 0,
      extras: extras || [],
    };

    // Find the user in the 'going' array and update their attendance data
    const updatedGoing = event.players.going.map((player) => {
      if (player.id === user.uid) {
        return { ...player, ...attendanceData }; // Update the current user's attendance data
      }
      return player; // Return other players unchanged
    });

    // Update the event object
    const updatedEvent = {
      ...event,
      players: { ...event.players, going: updatedGoing }, // Replace the old 'going' array with the updated one
    };

    console.log(attendanceData);

    // Update the document in the database
    await updateDocument(id, updatedEvent);

    // Clear the form fields
  };

  const handleRemoveSubEvent = (e) => {
    const str = e.target.innerHTML.trim(); // Get the event name from the element and trim any extra spaces

    // Find the index of the sub-event object where the name matches the string
    const index = subEvents.findIndex((subEvent) => subEvent.name === str);

    if (index > -1) {
      // Create a new array excluding the sub-event with the matched name
      const updatedSubEvents = [...subEvents];
      updatedSubEvents.splice(index, 1); // Remove the sub-event at the found index

      // Update the state with the new array
      setSubEvents(updatedSubEvents);
    }
  };

  useEffect(() => {
    const teamsSet = new Set();
    const unaffiliatedPlayersSet = new Set();
    event?.players?.going.forEach((player) => {
      const userDoc = users?.users?.find((user) => user.id === player.id);
      if (userDoc) {
        if (userDoc.profileData?.teams?.length > 0) {
          userDoc.profileData?.teams?.forEach((team) => teamsSet.add(team));
        } else {
          unaffiliatedPlayersSet.add(player.id);
        }
      }
    });
    event?.players?.interested?.forEach((player) => {
      const userDoc = users?.users?.find((user) => user.id === player);
      if (userDoc) {
        if (userDoc.profileData?.teams?.length > 0) {
          userDoc.profileData?.teams?.forEach((team) => teamsSet.add(team));
        } else {
          unaffiliatedPlayersSet.add(player);
        }
      }
    });
    const deletedTeamsSet = new Set();
    teamData?.teams?.forEach((team) => {
      if (team.deleted) {
        deletedTeamsSet.add(team.id);
      }
    });
    for (const team of teamsSet) {
      if (deletedTeamsSet.has(team)) {
        teamsSet.delete(team);
      }
    }
    setAttendingTeams([...teamsSet]);
    setUnaffiliatedPlayers([...unaffiliatedPlayersSet]);
  }, [event, users]);

  const clearForm = (e) => {
    e.preventDefault();
    setEventName("");
    setEventLocation("");
    setStartDate("");
    setEndDate("");
    setEventWebsite("");
    setTicketSite("");
    setSubEvents([]);
    setTeams([]);
    setNotes("");
    setClearInput(true);
    setPaint("");
  };

  const handleEditEvent = async (e) => {
    e.preventDefault();
    const data = {
      eventName,
      eventLocation,
      eventTown,
      venueName,
      startDate,
      endDate,
      eventWebsite,
      ticketSite,
      subEvents,
      teams,
      notes,
    };
    try {
      await updateDocument(id, data);
      handleToggleEventModal();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    if (arrival === getDayOptions()[getDayOptions().length - 1]?.value) {
      setArrivalSpecific(null);
    }
  }, [arrival]);

  useEffect(() => {
    setIsPending(true);

    const unsub = projectFirestore
      .collection("events")
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const event = { ...doc.data() };
          const deletedPlayers = users?.users?.filter((user) => user.deleted);
          const filteredGoingPlayers = event.players.going.filter(
            (player) => !deletedPlayers?.some((deletedPlayer) => deletedPlayer.id === player.id),
          );
          const filteredInterestedPlayers = event.players.interested.filter(
            (player) => !deletedPlayers?.some((deletedPlayer) => deletedPlayer.id === player),
          );
          event.players.going = filteredGoingPlayers;
          event.players.interested = filteredInterestedPlayers;
          setIsPending(false);
          setEvent(event);
        } else {
          setIsPending(false);
          setError("Could not find that event");
        }
      });

    return () => unsub();
  }, [id, users]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you wish to delete this event?")) {
      softDeleteDocument(id);
      navigate("/events");
    }
  };

  useEffect(() => {
    if (event && user) {
      const going = event.players.going.find((player) => player.id === user.uid);
      if (going) {
        setArrival(going.arrival);
        setArrivalSpecific(going.arrivalSpecific);
        setSleeping(going.sleeping);
        setTransport(going.transport);
        setPaint(going.paint);
        setExtras(going.extras);
      }
    }
    if (user) {
      const teamId = user.profileData?.primaryTeamId;
      const teamName = teamData?.teams?.find((t) => t.id === teamId)?.teamName;
      setPrimaryTeam({ id: teamId, name: teamName });
      if (teamFilterLogi === null || teamFilterLogi.label === undefined || teamFilterLogi.value === undefined) {
        setTeamFilterLogi({ value: teamId, label: teamName });
      }
    }
  }, [event, user, teamData]);

  const handleEdit = () => {
    setEventName(event.eventName);
    setEventLocation(event.eventLocation);
    setEventTown(event.eventTown);
    setVenueName(event.venueName);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setEventWebsite(event.eventWebsite);
    setTicketSite(event.ticketSite);
    setSubEvents(event.subEvents || []); // copy event.subEvents);
    setTeams(event.teams || []);
    setNotes(event.notes);
  };

  const handleRegisterSubEvent = (id, subevent) => {
    const players = [...subevent.players];
    players.push(user.uid);

    const updatedSubEvent = { ...subevent, players };
    const updatedSubEvents = event.subEvents.map((s) => (s.name === subevent.name ? updatedSubEvent : s));

    const update = { subEvents: updatedSubEvents };
    updateDocument(id, update);
  };
  const handleDeRegisterSubEvent = (id, subevent) => {
    const updatedPlayers = subevent.players.filter((playerId) => playerId !== user.uid);
    const updatedSubEvent = { ...subevent, players: updatedPlayers };
    const updatedSubEvents = event.subEvents.map((s) => (s.name === subevent.name ? updatedSubEvent : s));
    const update = { subEvents: updatedSubEvents };
    updateDocument(id, update);
  };
  const handleInterested = (id) => {
    const players = event.players;
    if (players.going.map((player) => player.id).includes(user.uid)) {
      handleDeRegister(id);
    }
    players.interested.push(user.uid);
    const update = { players };
    updateDocument(id, update);

    newsCreate({
      user: user.uid,
      category: "eventInterested",
      categoryName: event.eventName,
      entryId: id,
    });
  };

  const handleGoing = (id) => {
    const players = event.players;
    if (players.interested.includes(user.uid)) {
      handleDeRegister(id);
    }
    players.going.push({ id: user.uid, arrival: null, sleeping: null, transport: null });
    const update = { players };
    updateDocument(id, update);

    newsCreate({
      user: user.uid,
      category: "eventGoing",
      categoryName: event.eventName,
      entryId: id,
    });
  };

  const handleDeRegister = (id) => {
    const players = event.players;
    const uid = players.interested.indexOf(user.uid);

    // Find the index in the 'going' array by checking the 'id' field
    const uid2 = players.going.findIndex((player) => player.id === user.uid);

    // Remove from 'interested' if the user exists
    if (uid > -1) {
      players.interested.splice(uid, 1);
    }

    // Remove from 'going' if the user exists
    if (uid2 > -1) {
      players.going.splice(uid2, 1);
    }

    // Create the update object and update the document
    const update = { players };
    updateDocument(id, update);
  };

  const handleToggleShowLogisicsModal = () => {
    setShowLogisticsModal(!showLogisticsModal);
  };

  const handleToggleAssignTeamsModal = () => {
    const registeredTeams = event.teams.filter((team) => team.registered);
    setShowAssignTeamsModal(!showAssignTeamsModal);
  };
  const handleAssignTeams = (id) => {
    let update = {};
    Object.entries(teamAssignments).forEach(([id, teamName]) => {
      // Remove the ID from any other team it may already be registered to
      const oldTeamAssignments = event.teams;
      oldTeamAssignments.forEach((team) => {
        if (team.registeredTeams.includes(id) && team.name !== teamName) {
          team.registeredTeams = team.registeredTeams.filter((memberId) => memberId !== id);
        }
      });

      // Find the correct team by name and add the ID to registeredTeams if not already there
      const targetTeam = oldTeamAssignments.find((team) => team.name === teamName);
      if (targetTeam && !targetTeam.registeredTeams.includes(id)) {
        targetTeam.registeredTeams.push(id);
      }
      update = { teams: oldTeamAssignments };
    });
    updateDocument(id, update);
  };

  const handleTeamChange = (teamId, option) => {
    setTeamAssignments((prevAssignments) => ({
      ...prevAssignments,
      [teamId]: option.value,
    }));
  };

  return (
    <>
      <Spacer height="24px" />
      <div className="card">
        {isPending && (
          <div className="alert alert-info">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
          </div>
        )}
        {event?.deleted && <div className="alert alert-danger">This event has been deleted.</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {event && (
          <>
            <div className="container-lg ">
              <div className="row align-items-center g-5">
                <div className="col">
                  <button className="btn" onClick={() => navigate("/events")}>
                    &lt; Back to Events
                  </button>
                  <div className="bg-white bg-opacity-50 p-2 mb-2 rounded">
                    <h1>{event.eventName}</h1>
                    <div className="d-flex">
                      <span className="text-danger fw-bold">
                        {event.startDate !== event.endDate &&
                          `${event.startDate.split("-").reverse().join("/")} to ${event.endDate.split("-").reverse().join("/")}`}
                        {event.startDate === event.endDate && `${event.startDate.split("-").reverse().join("/")}`}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {event.eventWebsite && (
                      <a className="btn btn-primary me-1" href={`http://${event.eventWebsite}`} target="_blank" rel="noreferrer">
                        <i className="bi bi-laptop me-2"></i> Website
                      </a>
                    )}
                    {event.ticketSite && (
                      <a className="btn btn-primary me-1" href={`http://${event.ticketSite}`} target="_blank" rel="noreferrer">
                        <i className="bi bi-ticket-perforated me-2"></i> Get Tickets
                      </a>
                    )}
                    <i className="me-auto"></i>
                    {user &&
                      !event.players?.interested?.includes(user?.uid) &&
                      !event.players?.going?.map((player) => player.id).includes(user?.uid) && (
                        <>
                          <button className="btn btn-secondary ms-1 d-none d-md-inline" onClick={() => handleInterested(id)}>
                            <i className="bi bi-star me-1"></i> Interested
                          </button>
                          <button className="btn btn-secondary ms-1 d-none d-md-inline" onClick={() => handleGoing(id)}>
                            <i className="bi bi-check-circle me-1"></i> Going
                          </button>
                          <DropdownButton
                            id="attendanceToggle"
                            className="d-md-none ms-1"
                            variant="secondary"
                            title={
                              <span>
                                <i className="bi bi-x-circle me-1"></i> Not Going
                              </span>
                            }
                          >
                            <Dropdown.Item onClick={() => handleInterested(id)}>
                              <i className="bi bi-star-fill me-1"></i>Interested
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGoing(id)}>
                              <i className="bi bi-check-circle me-1"></i> Going
                            </Dropdown.Item>
                            <Dropdown.Item className="text-bg-primary">
                              <i className="bi bi-x-circle me-1"></i>Not Going
                            </Dropdown.Item>
                          </DropdownButton>
                        </>
                      )}
                    {event.players?.interested?.includes(user?.uid) && (
                      <>
                        <DropdownButton
                          id="attendanceToggle"
                          title={
                            <span>
                              <i className="bi bi-star-fill me-1"></i> Interested
                            </span>
                          }
                        >
                          <Dropdown.Item className="text-bg-primary">
                            <i className="bi bi-star-fill me-1"></i>Interested
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleGoing(id)}>
                            <i className="bi bi-check-circle me-1"></i> Going
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeRegister(id)}>
                            <i className="bi bi-x-circle me-1"></i>Not Going
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                    {event.players?.going?.map((player) => player.id).includes(user?.uid) && (
                      <>
                        <DropdownButton
                          id="attendanceToggle"
                          title={
                            <span>
                              <i className="bi bi-check-circle me-1"></i> Going
                            </span>
                          }
                        >
                          <Dropdown.Item onClick={() => handleInterested(id)}>
                            <i className="bi bi-star-fill me-1"></i>Interested
                          </Dropdown.Item>
                          <Dropdown.Item className="text-bg-primary">
                            <i className="bi bi-check-circle me-1"></i> Going
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeRegister(id)}>
                            <i className="bi bi-x-circle me-1"></i>Not Going
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                    {user && user.uid === event.user && (
                      <>
                        <DropdownButton align="end" className="ms-1" variant={"secondary"} id="eventAdminToggle" title=". . .">
                          <Dropdown.Item onClick={() => handleToggleEventModal()}>
                            <i className="bi bi-pencil-square me-1"></i>Edit Event
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDelete(id)}>
                            <i className="bi bi-file-earmark-x me-1"></i> Delete Event
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-lg">
              <div className="row p-1 justify-content-around ">
                <div className="col-md-12 col-lg-6  m-1 card text-dark bg-light p-3">
                  <h3>Details</h3>
                  <p>
                    <i className="bi bi-people me-2"></i>
                    {event.players?.interested?.length + event.players?.going?.length} responses ({event.players?.going?.length}{" "}
                    going, {event.players?.interested?.length} interested)
                  </p>
                  <p>
                    <i className="bi bi-laptop me-2"></i>
                    <a href={`http://${event.eventWebsite}`} target={"_blank"} rel="noreferrer">
                      {event.eventWebsite}
                    </a>
                  </p>
                  <p>
                    <i className="bi bi-ticket-perforated me-2"></i>
                    <a href={`https://${event.ticketSite}`} target={"_blank"} rel="noreferrer">
                      {event.ticketSite}
                    </a>
                  </p>
                  <p>
                    <i className="bi bi-geo-alt me-2"></i>
                    {event.eventLocation}
                  </p>
                  {event.subEvents && (
                    <p>
                      <i className="bi bi-list-nested me-2"></i>
                      {event.subEvents.length} sub events
                    </p>
                  )}
                  <p>
                    <i className="bi bi-square-half me-2" alt="Team"></i>
                    {event.teams?.map((team) => (
                      <span key={team.name} className="badge bg-secondary me-1">
                        {team.name}
                      </span>
                    ))}
                  </p>
                  {event.notes && (
                    <p>
                      <i className="bi bi-card-text me-2"></i>
                      {event.notes}
                    </p>
                  )}
                </div>
                <div className="col m-1 p-0 card">
                  <iframe
                    src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                      event.eventLocation,
                    )}&key=AIzaSyBijEr_Rrirj6rdNnDO_hkavhc-VvbTKP8`}
                    width="100%"
                    height="100%"
                    style={{ border: "0", minHeight: "300px" }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>

            {user && event && (!user?.profileData?.teams || user?.profileData?.teams?.length < 1) && (
              <div className="alert alert-warning my-1 mx-2">
                You are not a member of any teams! Help organise events by visiting the <Link to="/roster">roster</Link> and
                search for a team to join or create a new one.
              </div>
            )}

            {user && event && (
              <div className="container-lg">
                <div className="row p-1 justify-content-around">
                  <div className="col m-1 card text-dark bg-light p-3 d-flex">
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-0">Attendance</h3>
                        <small>Click a team card to view member attendance. Members may appear in multiple teams.</small>
                      </div>
                      <div className="col-4 d-flex">
                        {teamData?.teams?.find((team) => team.admins?.includes(user.uid)) && (
                          <div className="ms-auto">
                            <Button variant="secondary" className="mb-2" onClick={handleToggleAssignTeamsModal}>
                              Assign Team
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-around">
                        <span className="text-center">
                          <h2>
                            {event.players?.going?.length} <br />
                            going
                          </h2>
                        </span>
                        <span className="text-center">
                          <h2>
                            {event.players?.interested?.length} <br /> interested
                          </h2>
                        </span>
                      </div>{" "}
                    </div>
                    <div className="row my-1">
                      {event?.teams &&
                        event?.teams?.map((team) => {
                          return (
                            <div key={team.name} className="col-12 col-lg-6">
                              <div className="card p-3 m-1 h-100">
                                <h4>{team.name}</h4>
                                <div className="d-flex flex-wrap overflow-wrap">
                                  {team.registeredTeams?.map((registeredTeamId) => {
                                    const team = teamData?.teams?.find((u) => u.id === registeredTeamId);
                                    const goingIdsSet = new Set(event.players.going.map((player) => player.id));
                                    const interestedIdsSet = new Set(event.players.interested);
                                    const teamPlayers = users?.users?.filter((user) =>
                                      user.profileData.teams?.includes(registeredTeamId),
                                    );
                                    const teamPlayersGoing = teamPlayers.filter((player) => goingIdsSet.has(player.id)).length;
                                    const teamPlayersInterested = teamPlayers.filter((player) =>
                                      interestedIdsSet.has(player.id),
                                    ).length;
                                    return (
                                      <div
                                        key={registeredTeamId}
                                        className="player-attendance-card p-2 m-1 bg-light"
                                        onClick={() => handleToggleTeamModal(registeredTeamId)}
                                        role="button"
                                      >
                                        {/* <InfoCard teamData={teamData?.teams?.find((u) => u.id === registeredTeamId)} /> */}
                                        <div className="d-flex position-relative m-1">
                                          <Avatar src={team.avatar} className="align-self-center" />
                                          <span className="align-self-center ms-3">
                                            <div>
                                              {team?.teamName}{" "}
                                              <Link to={`/team/${team?.id}`}>
                                                <i className="bi bi-search"></i>
                                              </Link>
                                            </div>
                                            <div>
                                              {teamPlayersGoing} going | {teamPlayersInterested} maybe
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="row my-1">
                      <div className="col">
                        <div className="card p-3 m-1">
                          <h4>Unassigned Teams</h4>
                          <div className="d-flex flex-wrap overflow-wrap">
                            {attendingTeams
                              .filter((team) => !event?.teams?.some((eventTeam) => eventTeam.registeredTeams.includes(team)))
                              .map((team) => {
                                const teamPlayers = users?.users?.filter((user) => user.profileData.teams?.includes(team));
                                const teamPlayersGoing = teamPlayers.filter((player) =>
                                  event.players.going.map((player) => player.id).includes(player.id),
                                ).length;
                                const teamPlayersInterested = teamPlayers.filter((player) =>
                                  event.players.interested.includes(player.id),
                                ).length;
                                return (
                                  <div
                                    key={team}
                                    className="player-attendance-card p-2 m-1 bg-light"
                                    onClick={() => handleToggleTeamModal(team)}
                                    role="button"
                                  >
                                    {/* <InfoCard teamData={teamData?.teams?.find((u) => u.id === registeredTeamId)} /> */}
                                    <div className="d-flex position-relative m-1">
                                      <Avatar
                                        src={teamData?.teams?.find((t) => t.id === team)?.avatar}
                                        className="align-self-center"
                                      />
                                      <span className="align-self-center ms-3">
                                        <div>
                                          {teamData?.teams?.find((t) => t.id === team)?.teamName}{" "}
                                          <Link to={`/team/${team}`}>
                                            <i className="bi bi-search"></i>
                                          </Link>
                                        </div>
                                        <div>
                                          {teamPlayersGoing} going | {teamPlayersInterested} maybe
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            {unaffiliatedPlayers.length > 0 && (
                              <div
                                className="player-attendance-card p-2 m-1 bg-light"
                                onClick={() => handleToggleTeamModal("anon")}
                                role="button"
                              >
                                <div className="d-flex position-relative m-1">
                                  <Avatar className="align-self-center" />
                                  <span className="align-self-center ms-3">
                                    <div>Unaffiliated Players</div>
                                    <div>
                                      {
                                        unaffiliatedPlayers.filter((player) =>
                                          event.players.going.map((player) => player.id).includes(player),
                                        ).length
                                      }{" "}
                                      going |{" "}
                                      {
                                        unaffiliatedPlayers.filter((player) =>
                                          event.players.interested.map((player) => player).includes(player),
                                        ).length
                                      }{" "}
                                      maybe
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {user && user?.profileData?.teams && user?.profileData?.teams?.length > 0 && event?.startDate !== event?.endDate && (
              <div className="container-lg">
                <div className="row p-1 justify-content-around">
                  <div className="col m-1 card text-dark bg-light p-3 d-flex">
                    <div className="d-flex mb-2">
                      <h3>Team Logistics</h3>
                      <div className="ms-auto d-flex">
                        <div className="d-flex align-items-center">
                          <label className="me-2" htmlFor="team-select">
                            Select Team
                          </label>
                          <Select
                            id="team-select"
                            value={teamFilterLogi}
                            onChange={(option) => setTeamFilterLogi({ value: option.value, label: option.label })}
                            options={user.profileData.teams.map((team) => ({
                              value: team,
                              label: teamData?.teams?.find((t) => t.id === team)?.teamName,
                            }))}
                            isSearchable={false}
                          />
                        </div>
                        {event.startDate !== event.endDate && (
                          <div className="ms-1">
                            <Button onClick={() => setShowLogisticsOverviewModal(!showLogisticsOverviewModal)}>
                              Logistics Overview
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-around">
                        <span className="text-center">
                          <h2>
                            {
                              event.players?.going?.filter((player) => teamPlayersGoingLogi.some((tpg) => tpg.id === player.id))
                                .length
                            }{" "}
                            <br />
                            going
                          </h2>
                        </span>
                        <span className="text-center">
                          <h2>
                            {teamPlayersInterestedLogi.filter((tpi) => event.players?.interested.includes(tpi.id)).length} <br />{" "}
                            interested
                          </h2>
                        </span>
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div className="container">
                        <div className="card p-2">
                          {event.players?.going?.length > 0 && (
                            <div>
                              {event.startDate !== event.endDate && (
                                <table className="table table-bordered ">
                                  <thead className="text-center">
                                    <tr>
                                      <th className="">
                                        <span className="material-icons me-1">person</span>Player
                                      </th>
                                      <th className="d-none d-md-table-cell">
                                        <span className="material-icons me-1">schedule</span>Arriving
                                      </th>
                                      <th className="d-none d-md-table-cell">
                                        <span className="material-icons me-1">hotel</span>Sleeping
                                      </th>
                                      <th className="d-none d-md-table-cell">
                                        <span className="material-icons me-1">commute</span>Transport
                                      </th>
                                      <th className=" d-md-none">Logistics</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {event.players?.going
                                      ?.filter((player) => teamPlayersGoingLogi.map((tpg) => tpg.id).includes(player.id))
                                      .map((player) => {
                                        const playerData = users?.users?.find((u) => u.id === player.id);
                                        return (
                                          <tr key={player.id}>
                                            <td>
                                              <div className="d-flex align-content-center">
                                                <Avatar src={playerData?.profileData?.photoURL || defaultProfile} />
                                                <span className="ms-2 align-self-center">
                                                  {playerData?.profileData?.firstName} {playerData?.profileData?.surName}{" "}
                                                </span>
                                                {user.uid === player.id && (
                                                  <i
                                                    className="bi bi-pencil-fill text-primary ms-2 align-self-center d-md-none"
                                                    onClick={() => handleToggleShowLogisicsModal()}
                                                  ></i>
                                                )}
                                              </div>
                                            </td>
                                            {user.uid === player.id && event.startDate !== event.endDate && (
                                              <>
                                                <td className="d-md-none">
                                                  <div>
                                                    <span className="material-icons me-1">schedule</span>
                                                    {player.arrival}
                                                  </div>
                                                  <div>
                                                    <span className="material-icons me-1">hotel</span>
                                                    {player.sleeping}
                                                  </div>
                                                  <div>
                                                    <span className="material-icons me-1">commute</span>
                                                    {player.transport}
                                                  </div>
                                                </td>
                                                <td className="d-md-table-cell d-none">
                                                  {player.arrival}
                                                  {/* <Select
                                              options={getDayOptions()}
                                              value={{ value: arrival, label: arrival }}
                                              placeholder="Arrival"
                                              onChange={(e) => setArrival(e.value)}
                                            />{' '} */}
                                                </td>
                                                <td className="d-md-table-cell d-none">
                                                  {player.sleeping}
                                                  {/* <Select
                                              onChange={(e) => setSleeping(e.value)}
                                              value={{ value: sleeping, label: sleeping }}
                                              options={[
                                                { value: 'Tent', label: 'Tent' },
                                                { value: 'Vehicle', label: 'Vehicle' },
                                              ]}
                                              placeholder="Sleeping"
                                            /> */}
                                                </td>
                                                <td className="d-md-table-cell d-none">
                                                  {player.transport}
                                                  {/* <Select
                                              onChange={(e) => setTransport(e.value)}
                                              value={{ value: transport, label: transport }}
                                              options={[
                                                { value: 'Yes', label: 'Yes' },
                                                { value: 'No', label: 'No' },
                                              ]}
                                              placeholder="Transport"
                                            /> */}
                                                </td>
                                                <td className="d-md-table-cell d-none">
                                                  {/* <button
                                              className="btn btn-primary btn"
                                              onClick={(e) => handleSubmitAttendanceData(e)}
                                            >
                                              Save
                                            </button> */}
                                                  <button
                                                    className="btn btn-primary btn"
                                                    onClick={(e) => handleToggleShowLogisicsModal(e)}
                                                  >
                                                    Update
                                                  </button>
                                                </td>
                                              </>
                                            )}
                                            {user.uid !== player.id && event.startDate !== event.endDate && (
                                              <>
                                                <td className="d-md-none">
                                                  <div>
                                                    <span className="material-icons me-1">schedule</span>
                                                    {player.arrival || "-"}
                                                  </div>
                                                  <div>
                                                    <span className="material-icons me-1">hotel</span>
                                                    {player.sleeping || "-"}
                                                  </div>
                                                  <div>
                                                    <span className="material-icons me-1">commute</span>
                                                    {player.transport || "-"}
                                                  </div>
                                                </td>
                                                <td className="d-md-table-cell d-none">{player.arrival || "-"}</td>
                                                <td className="d-md-table-cell d-none">{player.sleeping || "-"}</td>
                                                <td className="d-md-table-cell d-none">{player.transport || "-"}</td>
                                              </>
                                            )}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          )}
                          {event.players?.interested?.length > 0 && event.players?.going?.length > 0 && (
                            <TextDivider text={"Interested"} />
                          )}
                          <div className="d-flex flex-wrap overflow-wrap">
                            {teamPlayersInterestedLogi
                              .filter((tpi) => event.players?.interested.includes(tpi.id))
                              .map((player) => {
                                return (
                                  <div key={player} className="player-attendance-card p-2 m-1 bg-light">
                                    <InfoCard playerData={users.users.find((u) => u.id === player.id)} />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {user && event.subEvents.length > 0 && (
              <section id="subEvents">
                <div className="container-lg">
                  <div className="row p-1 justify-content-around">
                    <div className="col m-1 card text-dark bg-light p-3">
                      <h3>Sub Events</h3>
                      <div className="row">
                        {event.subEvents?.map((subEvent) => {
                          return (
                            <div key={subEvent.name}>
                              <div className="card mb-3">
                                <div className="d-flex card-header">
                                  {subEvent.name}{" "}
                                  {user && !subEvent.players.includes(user?.uid) && (
                                    <button
                                      className="btn btn-sm btn-secondary ms-auto"
                                      onClick={() => handleRegisterSubEvent(id, subEvent)}
                                    >
                                      Register
                                    </button>
                                  )}
                                  {subEvent.players.includes(user?.uid) && (
                                    <button
                                      className="btn btn-sm btn-secondary ms-auto"
                                      onClick={() => handleDeRegisterSubEvent(id, subEvent)}
                                    >
                                      Deregister
                                    </button>
                                  )}
                                </div>
                                {subEvent.players?.length > 0 && (
                                  <div className="d-flex justify-content-start p-2">
                                    {subEvent?.players?.map((player) => {
                                      return (
                                        <InfoCard
                                          size={"sm"}
                                          key={player}
                                          playerData={users.users.find((u) => u.id === player)}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {/* <EventDiscussion event={event} /> */}
            {!user && (
              <div className="container-lg">
                <div className="row p-1 justify-content-around">
                  <div className="col m-1 card text-dark bg-light p-3 text-center">
                    Please login to view attendance information.
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <Modal show={showAssignTeamsModal} onHide={handleToggleAssignTeamsModal}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Teams to Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Your Teams</h5>
            <Form>
              <Form.Group className="mb-3" controlId="teams">
                {teamData?.teams
                  .filter((team) => team.admins.includes(user?.uid))
                  .map((team) => (
                    <Form.Group className="mb-3" controlId="team" key={team.id}>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon3" className="w-33">
                          {team.teamName}
                        </InputGroup.Text>
                        <div className="w-66" id={`team-${team.id}`}>
                          <Select
                            options={getTeamsOptions()}
                            placeholder="Event Team"
                            id={`team-${team.id}`}
                            onChange={(opt) => handleTeamChange(team.id, opt)}
                          />
                        </div>
                      </InputGroup>
                    </Form.Group>
                  ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAssignTeamsModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleAssignTeams(id)}>
              Assign
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEditEventModal} backdrop="static" onHide={handleToggleEventModal} id="editEventModal">
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Event Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Event Name*"
                  required
                  onChange={(e) => setEventName(e.target.value)}
                  value={eventName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="location">
                <Form.Label>Event Location*</Form.Label>
                <PlacesAutocomplete
                  setEventLocation={setEventLocation}
                  setEventTown={setEventTown}
                  setVenueName={setVenueName}
                  clearInput={clearInput}
                  setClearInput={setClearInput}
                  venueName={venueName}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="location">
                <Form.Label>Event Location*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Event Location*"
                  required
                  onChange={(e) => setEventLocation(e.target.value)}
                  value={eventLocation}
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label>Start Date*</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Event Start Date*"
                  required
                  onChange={(e) => {
                    const newStartDate = e.target.value;
                    const newEndDate = endDate ? (endDate < newStartDate ? newStartDate : endDate) : newStartDate;
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }}
                  value={startDate}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="endDate">
                <Form.Label>End Date*</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Event End Date*"
                  required
                  min={startDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="team">
                <Form.Label>
                  Teams{" "}
                  <span className="text-muted">
                    <small>(ex. Red v Blue)</small>
                  </span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Team Name"
                    onChange={(e) => setNewTeam(e.target.value)}
                    value={newTeam}
                    ref={teamInput}
                  />
                  <Button onClick={handleAddTeam} variant="outline-secondary" id="button-addon3">
                    Add
                  </Button>
                </InputGroup>
              </Form.Group>
              <p>Current teams (click to remove):</p>
              <ListGroup className="mb-3">
                {teams.map((i) => {
                  return (
                    <ListGroup.Item key={i.name} onClick={(e) => handleRemoveTeam(e)} name={i.name} action>
                      {i.name}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
              <Form.Group className="mb-3" controlId="eventWebsite">
                <Form.Label>Event Website</Form.Label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon3">https://</InputGroup.Text>
                  <Form.Control
                    type="url"
                    placeholder="Event Website"
                    onChange={(e) => setEventWebsite(e.target.value)}
                    value={eventWebsite}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="ticketSite">
                <Form.Label>Tickets Site</Form.Label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon3">https://</InputGroup.Text>
                  <Form.Control
                    type="url"
                    placeholder="Tickets Site"
                    onChange={(e) => setTicketSite(e.target.value)}
                    value={ticketSite}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="subEvents">
                <Form.Label>Sub Events</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Sub event"
                    onChange={(e) => setNewSubEvents(e.target.value)}
                    value={newSubEvents}
                    ref={subEventInput}
                  />
                  <Button onClick={handleAddSubEvent} variant="outline-secondary" id="button-addon2">
                    Add
                  </Button>
                </InputGroup>
              </Form.Group>
              <p>Current sub-events (click to remove):</p>
              <ul className="list-group list-hover mb-3">
                {subEvents.map((i) => {
                  return (
                    <li
                      className="text-align-start list-group-item list-group-item-action"
                      onClick={(e) => handleRemoveSubEvent(e)}
                      name={i.name || i}
                      key={i.name || i}
                    >
                      {i.name}
                    </li>
                  );
                })}
              </ul>
              <Form.Group className="mb-3" controlId="notes">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Notes"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleToggleEventModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEditEvent}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered id="teamModal" show={showTeamModal} onHide={handleToggleTeamModal}>
          <Modal.Header closeButton>
            {selectedTeam === "anon"
              ? "Unaffiliated Players"
              : teamData?.teams?.find((team) => team.id === selectedTeam)?.teamName || "Team"}
            {selectedTeam !== "anon" && (
              <Link to={`/team/${selectedTeam}`}>
                <i className="bi bi-search ms-1"></i>
              </Link>
            )}
          </Modal.Header>
          <Modal.Body className="pt-0">
            <TextDivider text={`Players going (${teamPlayersGoing.length})`} />
            <div className="d-flex flex-wrap overflow-wrap">
              {teamPlayersGoing &&
                teamPlayersGoing.map((player) => {
                  return (
                    <div key={player.id} className="player-attendance-card p-2 m-1 bg-light">
                      <InfoCard playerData={player} />
                    </div>
                  );
                })}
            </div>

            <TextDivider text={`Players interested (${teamPlayersInterested.length})`} />
            <div className="d-flex flex-wrap overflow-wrap">
              {teamPlayersInterested &&
                teamPlayersInterested.map((player) => {
                  return (
                    <div key={player.id} className="player-attendance-card p-2 m-1 bg-light">
                      <InfoCard playerData={player} />
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>

        <Modal centered id="logistitcsModal" show={showLogisticsModal}>
          <Modal.Header>Modify Attendance</Modal.Header>
          <Modal.Body className="pt-0">
            <TextDivider text="Logistics" />
            <form>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Arrival Day
                </span>
                <div className="w-75">
                  <Select
                    options={getDayOptions()}
                    placeholder="Arrival Day"
                    value={{ value: arrival, label: arrival }}
                    onChange={(e) => setArrival(e.value)}
                  />{" "}
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Arrival Time
                </span>
                <div className="w-75">
                  <Select
                    options={[
                      { value: "AM", label: "AM" },
                      { value: "PM", label: "PM" },
                      { value: "Evening", label: "Evening" },
                    ]}
                    placeholder="Arrival Time"
                    value={{ value: arrivalSpecific, label: arrivalSpecific }}
                    onChange={(e) => setArrivalSpecific(e.value)}
                    isDisabled={arrival === getDayOptions()[getDayOptions().length - 1]?.value}
                  />{" "}
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Sleeping
                </span>
                <div className="w-75">
                  <Select
                    onChange={(e) => setSleeping(e.value)}
                    value={{ value: sleeping, label: sleeping }}
                    options={[
                      { value: "Tent", label: "Tent" },
                      { value: "Car", label: "Car" },
                      { value: "Caravan", label: "Caravan" },
                      { value: "Sharing", label: "Sharing" },
                      { value: "Off-site", label: "Off-site" },
                    ]}
                    placeholder="Sleeping"
                  />
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Transport
                </span>
                <div className="w-75">
                  <Select
                    onChange={(e) => setTransport(e.value)}
                    value={{ value: transport, label: transport }}
                    options={[
                      { value: "Driving", label: "Driving" },
                      { value: "Sharing", label: "Sharing" },
                      { value: "Need", label: "Need" },
                    ]}
                    placeholder="Transport"
                  />
                </div>
              </div>
              <TextDivider text="Additional Information" />
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Paint
                </span>
                <div className="w-75">
                  <input
                    type="number"
                    min="0"
                    max="9"
                    className="form-control"
                    placeholder="Paint pre-order"
                    onChange={(e) => setPaint(e.target.value)}
                    value={paint || ""}
                  />
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Extras
                </span>
                <div className="w-75">
                  <CreatableSelect
                    isMulti
                    onChange={(e) => setExtras(e.map((item) => (typeof item === "string" ? item : item.value)))}
                    value={extras?.map((item) => ({ value: item, label: item }))}
                    options={[
                      { value: "Firepit", label: "Firepit" },
                      { value: "Gazebo", label: "Gazebo" },
                    ]}
                    placeholder="Any extras?"
                  />
                </div>
                <small className="text-muted ms-auto me-auto">Type to create a new option</small>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" data-bs-dismiss="modal" onClick={() => handleToggleShowLogisicsModal()}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={(e) => handleSubmitAttendanceData(e)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <LogisticsOverview
          team={teamFilterLogi}
          teamPlayers={teamPlayersLogi}
          teamPlayersGoing={teamPlayersGoingLogi}
          teamPlayersInterested={teamPlayersInterestedLogi}
          event={event}
          showLogisticsOverviewModal={showLogisticsOverviewModal}
          setShowLogisticsOverviewModal={setShowLogisticsOverviewModal}
          userList={users}
        />
      </div>
    </>
  );
}
