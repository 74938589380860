import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function LogisticsOverview({
  team,
  teamPlayers,
  teamPlayersGoing,
  teamPlayersInterested,
  event,
  showLogisticsOverviewModal,
  setShowLogisticsOverviewModal,
  userList,
}) {
  const [numCars, setNumCars] = useState(0);
  const [numTents, setNumTents] = useState(0);
  const [numCaravans, setNumCaravans] = useState(0);
  const [players, setPlayers] = useState([]);
  const [goingPlayers, setGoingPlayers] = useState([]);
  const [interestedPlayers, setInterestedPlayers] = useState([]);

  useEffect(() => {
    if (event) {
      const { players } = event;
      const filteredPlayersGoing = players?.going.filter((player) =>
        teamPlayersGoing.some((teamPlayer) => teamPlayer.id === player.id),
      );
      const filteredPlayersInterested = players?.interested.filter((playerId) =>
        teamPlayersInterested.some((teamPlayer) => teamPlayer.id === playerId),
      );

      setPlayers(players);
      setGoingPlayers(filteredPlayersGoing);
      setInterestedPlayers(filteredPlayersInterested);
      setNumCars(filteredPlayersGoing.filter((player) => player.transport?.toLowerCase() === "driving").length);
      setNumTents(filteredPlayersGoing.filter((player) => player.sleeping?.toLowerCase() === "tent").length);
      setNumCaravans(filteredPlayersGoing.filter((player) => player.sleeping?.toLowerCase() === "caravan").length);
    }
  }, [event, teamPlayersGoing, teamPlayersInterested]);
  return (
    <Modal
      id="logisticsOverviewModal"
      show={showLogisticsOverviewModal}
      fullscreen
      onHide={() => setShowLogisticsOverviewModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Logistics Overview - {team?.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card p-3 m-2">
          <h4>{event?.eventName}</h4>
          <div>Players going: {goingPlayers?.length}</div>
          <div>Possible additional attendees: {interestedPlayers?.length}</div>
        </div>
        <div className="card p-3 m-2">
          <div>Arrival times:</div>
          <div className="card p-3 m-2">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col">Morning</th>
                  <th scope="col">Afternoon</th>
                  <th scope="col">Evening</th>
                </tr>
              </thead>
              <tbody>
                {["Friday", "Saturday", "Sunday"].map((day) => (
                  <tr key={day}>
                    <th scope="row">{day}</th>
                    {["AM", "PM", "Evening"].map((time) => {
                      return (
                        <td key={time}>
                          {goingPlayers?.filter((player) => player.arrival === day && player.arrivalSpecific === time).length}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card p-3 m-2">
          <div>Space required:</div>
          <div className="card p-3 m-2">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Cars</th>
                  <th scope="col">Tents</th>
                  <th scope="col">Caravans</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{numCars}</td>
                  <td>{numTents}</td>
                  <td>{numCaravans}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card p-3 m-2">
          Paint orders:
          <div className="card p-3 m-2">
            Total Paint:{" "}
            {goingPlayers
              ?.reduce(
                (total, player) =>
                  total + (player.paint ? player.paint.match(/\d+/g).reduce((acc, curr) => acc + parseInt(curr, 10), 0) : 0),
                0,
              )
              .toString()}
          </div>
        </div>
        <div className="card p-3 m-2">
          Detailed list:
          <ul className="list-group m-2">
            {goingPlayers.map((player) => {
              return (
                <li key={player.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <span>
                    {userList?.users?.find((user) => user.id === player.id)?.profileData?.firstName || player.id}{" "}
                    {userList?.users?.find((user) => user.id === player.id)?.profileData?.surName} ({player.paint || 0} paint)
                  </span>
                  <span>
                    {player.arrival} - {player.arrivalSpecific}
                    {player.sleeping ? ", " + player.sleeping : ""} {player.transport ? ", " + player.transport : ""}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
