import React from "react";
import defaultProfile from "../../assets/defaultProfile.webp";

export default function Avatar({ src }) {
  return (
    <div className="avatar">
      <img src={src || defaultProfile} alt="" />
    </div>
  );
}
